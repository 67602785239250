import axios from "axios";

export default {
  
  login(payload) {
      return axios.post("/auth/login", payload);
  },
  passwordRecovery(payload) {
    return axios.post("/auth/password-recovery", payload);
  },
  checkToken(payload) {
    return axios.post("/auth/check-recovery-password-token", payload);
  },
  passwordReset(payload) {
    return axios.post("/auth/password-reset", payload);
  },
  getProfile(params) {
    return axios.get("/users/profile", params);
  },
  /*recoveryClientCode(payload) {
    return axios.post("/auth/recover-client-code", payload);
  },*/
  

};