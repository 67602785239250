<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import HeroIcon from '@/Components/UI/HeroIcon.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

const props = defineProps({
  title: String,
  show: Number, // Recibe 0 o 1
  width: {
    type: String,
    default: 'max-w-md'
  },
  closeOnBackdropClick: {
    type: Boolean,
    default: false
  },
  showCloseButton: {
    type: Boolean,
    default: true
  },
  backdropOpacity: { // 🔹 Nueva prop para cambiar la opacidad del fondo (por defecto 25)
    type: Number,
    default: 25
  },
  backdropBlur: { // 🔹 Nueva prop para agregar blur al fondo (por defecto md)
    type: String,
    default: '5px'
  }
});


const emit = defineEmits(['onShown', 'onClosed']);

// Convierte el valor numérico de `show` en un booleano para la visibilidad del modal
const showModal = ref(props.show === 1);

// Observa cambios en la prop `show` para actualizar `showModal`
watch(() => props.show, (newShow) => {
  showModal.value = newShow === 1;
  if (newShow === 1) {
    emit('onShown');
  }
});

// Ajusta la definición de closeModal para aceptar un parámetro opcional
const closeModal = (isBackdropClick = false) => {
  // Si se hace clic en el backdrop y closeOnBackdropClick es false, no cerrar el modal
  if (isBackdropClick && !props.closeOnBackdropClick) {
    return;
  }
  // Cierra el modal y emite el evento onClosed
  showModal.value = false;
  emit('onClosed', true);
};



</script>



<template>
  <TransitionRoot :show="showModal" as="template">
    <Dialog as="div" @close="() => closeModal(true)" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div 
          class="fixed inset-0 bg-black" 
          :style="{
            backgroundColor: `rgba(0, 0, 0, ${props.backdropOpacity / 100})`,
            backdropFilter: `blur(${props.backdropBlur})`
          }"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              :class="`w-full ${props.width} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`">
              <DialogTitle as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center">
                {{ props.title }}
                <button v-if="props.showCloseButton" @click="closeModal()"
                  class="inline-flex items-center justify-center rounded-full border border-gray-400 bg-white p-1 h-8 w-8 hover:bg-gray-200">
                  <HeroIcon iconName="XMark" class="h-5 w-5 text-gray-400" />
                </button>

              </DialogTitle>
              <div class="mt-2">
                <slot></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
