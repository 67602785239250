<script setup>
import { defineProps, defineEmits } from 'vue';
import HeroIcon from '@/Components/UI/HeroIcon.vue'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  customClasses: {
    type: String,
    default: ''
  },
  tooltip: String,
  icon: String,
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['click']);

const handleClick = (event) => {
  if (props.disabled) {
    event.stopImmediatePropagation(); // Previene que se propague el evento
    return; // Salimos de la función si el botón está deshabilitado
  }

  emit('click', event); // Solo emite si no está deshabilitado
};
</script>

<template>
  <button 
    :class="[
      'inline-flex items-center justify-center pl-2 pr-4 py-2 font-medium rounded-lg duration-150',
      customClasses,
      disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-800 hover:bg-blue-500 active:bg-blue-900'
    ]"
    :disabled="disabled"
    :aria-disabled="disabled.toString()"
    @click="handleClick">
    <!-- Icono a la izquierda con margen condicional -->
    <HeroIcon 
      v-if="icon" 
      :iconName="icon" 
      :tooltip="tooltip" 
    />
    <!-- Contenido del botón y loading spinner -->
    <span class="flex items-center" :class="{'ml-1': icon}">
      <span v-if="loading" class="loading loading-spinner-btn-primary loading-xs mr-2"></span>
      <slot></slot> <!-- Contenido textual del botón -->
    </span>
  </button>
</template>



