import { defineStore } from 'pinia'
import * as Sentry from "@sentry/vue"
const tokenExists = localStorage.getItem("userToken") != null;

const getDefaultState = () => ({
  profile: null,
  role: null,
  //token: localStorage.getItem("userToken") || null,
  token: null,
  isAdmin: false,
  attendanceStatus: null, // 0 - Out || 1 - In (0 fichaje inactivo, 1 fichaje activo)

  //user: null
});

export const useUserStore = defineStore('user', {
  state: getDefaultState,
  persist: true,
  actions: {
    setLogin(payload) {

      const { user, token } = payload;
      
      this.profile = user;
      this.token = token;
      this.isAdmin = user.admin || false;
      
      localStorage.setItem("userToken", token);

      if (import.meta.env.VITE_APP_ENV === "production") {

        Sentry.setUser({
          id: `${user.customer_code}_${user.id}`,
          email: user.email,
      });
      }

    },
    setProfile(user) {
      this.profile = user;
      this.isAdmin = user.admin;
    },
    setAttendanceStatus(attendanceStatus) {
      this.attendanceStatus = attendanceStatus
    },
    setToken(payload){

      const { token } = payload
      this.token = token
      localStorage.setItem("userToken", token)

    },
    setLogout() {
      this.$reset(); // Resetear el store a su estado inicial
      localStorage.removeItem("userToken");
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getProfile: (state) => state.profile,
    getIsAdmin: (state) => state.isAdmin,
    getAttendanceStatus: (state) => state.attendanceStatus,
  },
})