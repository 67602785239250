import { defineStore } from "pinia";
import { useUserStore } from "./user";

export const useIdleStore = defineStore("idle", {
    state: () => ({
        isIdle: false,
        sessionExpired: false, // variable para controlar si la sesión ha expirado
        idleTime: 10 * 60 * 1000, // 10 minutos de inactividad
        //idleTime: 10 * 1000, // 10 segundo de inactividad
        timer: null,
    }),

    actions: {

        startTimer() {
            this.resetTimer();
        },

        resetTimer() {
            //  Si la sesión ya está expirada, no reiniciar el temporizador
            if (this.sessionExpired) {
                return;
            }

            this.isIdle = false;
            this.sessionExpired = false; // Ocultar modal si el usuario vuelve a estar activo

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                this.sessionExpired = true;
            }, this.idleTime);
        },

        resetIdleSession(){
            this.sessionExpired = false;
        }


    },
});
