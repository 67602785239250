<script setup>
import { defineProps, ref } from 'vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

defineProps({
  labelFor: String,
  required: {
    type: Boolean,
    default: false
  },
  tooltip: {
    type: String,
    default: ''
  }
})

const showTooltip = ref(false);
</script>

<template>
  <label :class="{ required: required }" class="block text-sm font-medium leading-6 text-gray-900 relative" :for="labelFor">
    <slot></slot>
    <span v-if="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false" class="tooltip-container">
      <QuestionMarkCircleIcon class="h-5 w-5 ml-2 text-gray-400 cursor-pointer" />
      <span v-if="showTooltip" class="tooltip-content">
        {{ tooltip }}
      </span>
    </span>
  </label>
</template>

<<style>
.required:after {
  content:" *";
  color: red;
}
.tooltip-container {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
.tooltip-content {
  visibility: hidden;
  width: 140px;
  background-color:#F1F5F9;
  font-size: 0.8rem;
  color: #94a0b7;
  line-height: 1rem;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%; /* Centrado justo encima del icono */
  transform: translateX(-50%); /* Asegura un centrado preciso */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #F1F5F9 transparent transparent transparent;
}
.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
</style>
