import axios from "axios";

export default {
  getCenters(){
    return axios.get("/settings/centers");
  },
  getAllCenters(){
    return axios.get("/settings/centers/all");
  },
  getAllCentersByCompany(company_id){
    return axios.get("/settings/centers/all-by-company/"+company_id);
  },
  getCenter(center_id){
    return axios.get("/settings/centers/"+center_id);
  },
  createCenter(payload) {
    return axios.post("/settings/centers/", payload);
  },
  updateCenter(center_id, payload) {
    return axios.post("/settings/centers/" + center_id, payload);
  },
  deleteCenter(center_id) {
    return axios.delete("/settings/centers/" + center_id);
  },
  getCenterHours(center_id){
    return axios.get("/settings/centers/"+center_id+"/hours");
  },
  createHours(center_id, payload) {
    return axios.post("/settings/centers/"+center_id+"/hours", payload);
  },
  getWhatsAppInstances(center_id){
    return axios.get("/settings/centers/"+center_id+"/whatsapp");
  },
  getAllWhatsAppInstances(){
    return axios.get("/settings/centers/whatsapp/all-instances");
  },
  createWhatsAppInstance(center_id, payload) {
    return axios.post("/settings/centers/"+center_id+"/whatsapp", payload);
  },
  deleteWhatsAppInstance(ws_id,center_id,ws_instance_name) {
    return axios.delete("/settings/centers/" + ws_id +'/'+ center_id+"/whatsapp/"+ws_instance_name);
  },
  testWhatsAppInstance(center_id, payload) {
    return axios.post("/settings/centers/" + center_id+"/test-whatsapp-instance", payload);
  },
  whatsAppInstanceConnect(center_id, payload) {
    return axios.post("/settings/centers/" + center_id+"/whatsapp-instance-connect", payload);
  },
};