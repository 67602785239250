<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router'
import { showNotification } from "@/utils/notifications"
import { useUserStore } from '@/stores/user'
import { useLayoutStore } from '@/stores/layout.js'
import { useIdleStore } from "@/stores/idle"
import TextInput from '@/Components/UI/TextInput.vue'
import AlertWarning from '@/Components/UI/Alerts/AlertWarning.vue'
import Label from '@/Components/UI/Label.vue'
import ButtonPrimary from '@/Components/UI/ButtonPrimary.vue'
import ButtonDanger from '@/Components/UI/ButtonDanger.vue'
import authApi from "@/services/api/auth/auth"

const idleStore = useIdleStore()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
const router = useRouter();

const password = ref();

const user = computed(() => ({
  id: userStore.profile?.id || "",
  name: userStore.profile?.name || "Usuario",
  email: userStore.profile?.email || "",
  customer_code: userStore.profile?.customer_code || "",
}));

const submit = async () => {

    try {

        layoutStore.isLoading = true

        let payload = {
            email: user.value.email,
            password: password.value,
            customer_code: user.value.customer_code
        }

        let response = await authApi.login(payload)

        userStore.setToken(response.data)
        idleStore.sessionExpired = false

    } catch (error) {
        const errorMsg = error.response?.data?.errors ?? error.response.data;
        showNotification({ type: 'error-html', message: errorMsg });
    } finally {
        layoutStore.isLoading = false
    }
};

const logout = () => {
    idleStore.resetIdleSession()
    router.push('/logout'); // Redirige a la ruta de logout
}

</script>

<template>
    <form @submit.prevent="submit()" class="space-y-5">
        <div class="grid grid-cols-1 gap-y-8 ">
            <div class="w-full grid grid-cols-12 gap-x-6 gap-y-6">

                <div class="col-span-12 grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <AlertWarning :info="$t('authIdle.modal.info', {'username': user.name})" class="mb-2"/>
                        <Label for="password" required>{{ $t('authIdle.modal.password') }}</Label>
                        <div class="mt-2">
                            <TextInput v-model="password" id="password" type="password" :placeholder="$t('authIdle.modal.password')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space-y-2">
            <ButtonPrimary customClasses="w-full text-white">{{ $t('authIdle.modal.login') }}</ButtonPrimary>
            <ButtonDanger @click.prevent="logout" customClasses="w-full text-white">{{ $t('authIdle.modal.logout') }}</ButtonDanger>
        </div>
    </form>
</template>