<script setup>
import { ref, watch } from 'vue';

// Define las props
const props = defineProps({
  modelValue: [String, Number],
  id: String,
  type: { type: String, required: true, default: "text" },
  placeholder: String,
  icon: Function,
  disabled: { type: Boolean, required: false, default: false },
  min: { type: [Number, String], default: null },  // Aceptar número o string para flexibilidad
  max: { type: [Number, String], default: null }
});

// Crea una referencia local que será el valor del input
const inputValue = ref(props.modelValue);

// Observa cambios en modelValue para actualizar inputValue cuando el valor externo cambie
watch(() => props.modelValue, (newValue) => {
  inputValue.value = newValue;
});

// Función para manejar el ajuste del valor al soltar una tecla
const handleKeyup = () => {
  if (props.type === 'number') {
    let value = parseFloat(inputValue.value);
    if (isNaN(value)) {
      value = '';
    }

    if (props.min !== null && value < parseFloat(props.min)) {
      value = parseFloat(props.min);
    } else if (props.max !== null && value > parseFloat(props.max)) {
      value = parseFloat(props.max);
    }

    // Actualiza el valor sólo si es necesario para evitar bucles innecesarios
    if (value !== inputValue.value) {
      inputValue.value = value;
      emit('update:modelValue', value);
      emit('change', value);
    }
  }
};

// Importa emit desde el contexto
const emit = defineEmits(['change', 'update:modelValue']);

// Emite el evento update:modelValue al componente padre cada vez que el valor del input cambie
const updateValue = (event) => {
  let value = event.target.value;

  // Si el tipo es number, intenta parsear el valor como float
  if (props.type === 'number') {
    value = parseFloat(value);
    if (isNaN(value)) {
      value = '';
    }
  }

  inputValue.value = value;
  emit('update:modelValue', value);
  emit('change', value);
};
</script>

<template>
  <div class="relative w-full">
    <component :is="icon" class="w-6 h-6 text-blue-300 absolute left-3 inset-y-0 my-auto" />
    <input :id="id" :type="type" :placeholder="placeholder" :value="inputValue" @keyup="handleKeyup"
      @input="updateValue" :disabled="disabled" autocomplete="nope" :min="type === 'number' ? min : undefined"
      :max="type === 'number' ? max : undefined" :class="[
        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
        { 'bg-slate-100 text-slate-400': disabled }
      ]" />
  </div>
</template>
