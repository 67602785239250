import axios from "axios";

export default {

  getCalendarSettings() {
    return axios.get("/calendar/settings");
  },
  getCenters() {
    return axios.get("/calendar/centers");
  },
  getResources() {
    return axios.get("/calendar/resources");
  },
  getAppointments(payload) {
    return axios.post("/calendar/listAppointments", payload);
  },
  createAppointment(payload) {
    return axios.post("/calendar/appointment", payload);
  },
  updateAppointment(appointmentId, payload) {
    return axios.post("/calendar/appointment/" + appointmentId, payload);
  },
  moveOrResizeAppointment(appointmentId, payload) {
    return axios.post("/calendar/appointment/" + appointmentId+'/moveOrResize', payload);
  },
  noShows(appointmentId, payload) {
    return axios.post("/calendar/appointment/" + appointmentId+'/noShows', payload);
  },
  attends(appointmentId) {
    return axios.get("/calendar/appointment/" + appointmentId+'/attends');
  },
  deleteAppointment(appointmentId) {
    return axios.delete("/calendar/appointment/" + appointmentId);
  },
  cancelAppointment(appointmentId) {
    return axios.post("/calendar/appointment/cancel/" + appointmentId);
  },
  pasteAppointment(appointmentId, payload) {
    return axios.post("/calendar/appointment/paste/" + appointmentId, payload);
  },
  blockDates(payload) {
    return axios.post("/calendar/blockDates", payload);
  },
  updateBlockDates(appointmentId, payload) {
    return axios.post("/calendar/blockDates/" + appointmentId, payload);
  },
  redeemVoucher(appointmentId, payload) {
    return axios.post("/calendar/appointment/redeem-voucher/" + appointmentId, payload);
  },
  appointmentServices(appointmentId) {
    return axios.get("/calendar/appointmentServices/" + appointmentId);
  },
  toogleWhatsAppReminders(payload){
    return axios.post("/calendar/toggleWhatsAppReminders/", payload);
  },
  getAppointment(appointmentId) {
    return axios.get("/calendar/appointment/"+appointmentId);
  },
  checkIfexistWhatsAppRemindersScheduled(){
    return axios.get("/calendar/checkIfexistWhatsAppRemindersScheduled/");
  },
  deleteWhatsAppRemindersScheduled(){
    return axios.post("/calendar/deleteWhatsAppRemindersScheduled");
  },
  sendAutomaticWhatsAppReminder(payload){
    return axios.post("/calendar/sendAutomaticWhatsAppReminder/", payload);
  },


};